// TODO : Remove this barrel file when a better flag strategy has been find

import type { StaticImageData } from "next/image"

import ca from "./ca.png"
import cs from "./cs.png"
import da from "./da.png"
import de from "./de.png"
import en from "./en.png"
import es from "./es.png"
import fi from "./fi.png"
import fr from "./fr.png"
import gl from "./gl.png"
import it from "./it.png"
import nl from "./nl.png"
import no from "./no.png"
import pl from "./pl.png"
import pt from "./pt.png"
import sv from "./sv.png"

type FlagsList = Record<string, StaticImageData>

export const flags: FlagsList = { ca, cs, da, de, en, es, fi, fr, gl, it, nl, no, pl, pt, sv }
